<template>
  <div>
    <div class="header">
      <div class="t">
        <img @click="go" src="../assets/z1.png" alt="" /><span
          >模拟积分系统</span
        >
      </div>
      <div class="d">
        请在符合您情况的选项内搭勾 <br />或在文本框中填写相应的数字<br />与内容
      </div>
    </div>
    <div class="content">
      <div class="title">基础指标和分值</div>
      <!-- //1 -->
      <div class="item1">
        <div class="subject">1、您的年龄（最高30分）</div>
        <div class="content1">
          年龄
          <input
            v-model="form.radio1"
            type="number"
            style="
              margin: 0 0.2rem;
              width: 1.46rem;
              background: #ffffff;
              border: 0.02rem solid #d9d9d9;
            "
          />周岁
        </div>
      </div>
      <van-divider />
      <!-- //2 -->
      <div class="item1">
        <div class="subject">2、教育背景（最高110分）</div>
        <div class="js">学历与职称，只可二选一进行积分</div>
        <div class="content1">
          <van-radio-group v-model="form.radio2">
            <van-radio icon-size=".24rem" name="50" class="btn"
              >大专（高职）学历</van-radio
            >
            <van-radio icon-size=".24rem" name="60" class="btn"
              >大学本科学历</van-radio
            >
            <van-radio icon-size=".24rem" name="90" class="btn"
              >大学本科学历和学士学位</van-radio
            >
            <van-radio icon-size=".24rem" name="100" class="btn"
              >硕士研究生学历学位</van-radio
            >

            <van-radio icon-size=".24rem" name="110" class="btn"
              >博士研究生学历学位</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //3 -->
      <div class="item1">
        <div class="subject">3、专业技术职称和技能等级（最高140分）</div>
        <div class="js">学历与职称，只可二选一进行积分</div>
        <div class="content1">
          <van-radio-group v-model="form.radio3">
            <van-radio icon-size=".24rem" name="0" class="btn"
              >无专业技术职称和技术等级</van-radio
            >
            <van-radio icon-size=".24rem" name="15" class="btn"
              >技能类国家职业资格五级</van-radio
            >
            <van-radio icon-size=".24rem" name="30" class="btn"
              >技能类国家职业资格四级</van-radio
            >
            <van-radio icon-size=".24rem" name="60" class="btn"
              >技能类国家职业资格三级</van-radio
            >

            <van-radio icon-size=".24rem" name="100" class="btn"
              >技能类国家职业资格二级或中级职称</van-radio
            >
            <van-radio icon-size=".24rem" name="140" class="btn"
              >技能类国家职业资格一级或高级职称</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //4 -->
      <div class="item1">
        <div class="subject">
          4、在本市工作及缴纳职工社会保险年限（每一年积3分）
        </div>
        <div class="content1">
          <van-radio-group v-model="form.radio4">
            <van-radio icon-size=".24rem" name="0" class="btn"
              >未在上海缴纳社会保险</van-radio
            >
            <van-radio icon-size=".24rem" name="1" class="btn"
              >缴纳城镇职工社会保险，已缴纳<input
                type="number"
                v-model="radio4_con"
                style="
                  margin: 0 0.2rem;
                  width: 0.5rem;
                  background: #ffffff;
                  border: 0.02rem solid #d9d9d9;
                "
              />年</van-radio
            >
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="content" style="margin-top: 0.5rem">
      <div class="title">加分指标及分值</div>
      <!-- //6 -->
      <div class="item1">
        <div class="subject">6、投资纳税或带动本地就业（最高120分）</div>
        <div class="content1">
          <van-radio-group v-model="form.radio6">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="60" class="btn"
              >是，最近三年纳税总额<input
                type="number"
                v-model="radio6_con"
                style="
                  margin: 0 0.2rem;
                  width: 0.5rem;
                  background: #ffffff;
                  border: 0.02rem solid #d9d9d9;
                "
              />万元 （您的占股百分比 %），企业聘用<input
                type="number"
                style="
                  margin: 0 0.2rem;
                  width: 0.5rem;
                  background: #ffffff;
                  border: 0.02rem solid #d9d9d9;
                "
              />
              名上海户籍人员。</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //7 -->
      <div class="item1">
        <div class="subject">7、纳税保险社会基数（最高120分）</div>
        <div class="content1">
          <van-radio-group v-model="form.radio7">
            <van-radio icon-size=".24rem" name="0" class="btn"
              >最近4年内累计36个月缴纳职工社会保险费低于上海市上年度职工平均工资80</van-radio
            >
            <van-radio icon-size=".24rem" name="25" class="btn"
              >持证人最近4年内累计36个月在本市缴纳职工社会保险费基数等于以及高于本市上年度职工
              社会平均工资80%低于1倍的,积25分。</van-radio
            >
            <van-radio icon-size=".24rem" name="50" class="btn"
              >持证人最近4年内累计36个月在本市缴纳职工
              社会保险费基数等于以及高于本市上年度职工
              社会平均工资1倍低于2倍的,积50分。</van-radio
            >
            <van-radio icon-size=".24rem" name="100" class="btn"
              >青证人最近4年内累计36个月在本市缴纳职工
              社会保险基数等于以及高于本市上年度职工
              社会平均工资2倍低于3倍的,积100分。</van-radio
            >

            <van-radio icon-size=".24rem" name="120" class="btn"
              >持证人最近3年内累计24个月在本市缴纳职工
              社会保险费基数等于本市上年度职工社会平均
              工资3倍的,积120分</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //8 -->
      <div class="item1">
        <div class="subject">8、特定的公共服务领域(5年后,1年积4分)</div>

        <div class="content1">
          <van-radio-group v-model="form.radio8">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="15" class="btn"
              >是,至今已工作<input
                v-model="radio8_con"
                type="number"
                style="
                  margin: 0 0.2rem;
                  width: 0.5rem;
                  background: #ffffff;
                  border: 0.02rem solid #d9d9d9;
                "
              />年</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //9 -->
      <div class="item1">
        <div class="subject">9、远郊重点区城(5年后,1年积2分)</div>
        <div class="content1">
          <van-radio-group v-model="form.radio9">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="1" class="btn"
              >是,至今已居住<input
                v-model="radio9_con"
                type="number"
                style="
                  margin: 0 0.2rem;
                  width: 0.5rem;
                  background: #ffffff;
                  border: 0.02rem solid #d9d9d9;
                "
              />年</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //10 -->
      <div class="item1">
        <div class="subject">10、是否全日制应届高校大学毕业生(10分)</div>
        <div class="content1">
          <van-radio-group v-model="form.radio10">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="10" class="btn">是</van-radio>
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //11 -->
      <div class="item1">
        <div class="subject">
          11、您在上海工作期间是否获得表彰奖励(最高110分)
        </div>
        <div class="content1">
          <van-radio-group v-model="form.radio11">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="30" class="btn"
              >获得过上海市专项表彰奖励</van-radio
            >
            <van-radio icon-size=".24rem" name="60" class="btn"
              >获得过上海市综合性表彰奖励</van-radio
            >
            <van-radio icon-size=".24rem" name="110" class="btn"
              >获得过上海市政府表彰奖励</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //12 -->
      <div class="item1">
        <div class="subject">12、配偶为本市户籍人员(1年积4分）</div>
        <div class="content1">
          <van-radio-group v-model="form.radio12">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="1" class="btn"
              >是，结婚已满<input
                v-model="radio12_con"
                type="number"
                style="
                  margin: 0 0.2rem;
                  width: 0.5rem;
                  background: #ffffff;
                  border: 0.02rem solid #d9d9d9;
                "
              />年</van-radio
            >
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="content" style="margin-top: 0.5rem">
      <div class="title">减分指标及分值</div>
      <!-- //13 -->
      <div class="item1">
        <div class="subject">13、三年内申请积分提供虚假材料</div>
        <div class="content1">
          <van-radio-group v-model="form.radio13">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="-150" class="btn"
              >一次</van-radio
            >
            <van-radio icon-size=".24rem" name="-300" class="btn"
              >二次</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //14 -->
      <div class="item1">
        <div class="subject">14、五年内行政拘留记录</div>
        <div class="content1">
          <van-radio-group v-model="form.radio14">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="-50" class="btn"
              >一次</van-radio
            >
            <van-radio icon-size=".24rem" name="-100" class="btn"
              >二次</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //15 -->
      <div class="item1">
        <div class="subject">14、五年内一般刑事犯罪记录</div>
        <div class="content1">
          <van-radio-group v-model="form.radio15">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="-150" class="btn"
              >一次</van-radio
            >
            <van-radio icon-size=".24rem" name="-300" class="btn"
              >二次</van-radio
            >
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="content" style="margin-top: 0.5rem; margin-bottom: 50px">
      <div class="title">积分一票否决</div>
      <div class="item1">
        <div class="subject">16、违反国家及本市计划生育政策</div>
        <div class="content1">
          <van-radio-group v-model="form.radio16">
            <van-radio icon-size=".24rem" name="0" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="1" class="btn"
              >未婚先育</van-radio
            >
            <van-radio icon-size=".24rem" name="2" class="btn">超生</van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="btn1" @click="btn">提交</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: null,
      radio4_con: "",
      radio6_con: "",
      radio8_con: "",
      radio9_con: "",
      radio12_con: "",
      form: {
        radio1: "",
        radio2: "",
        radio3: "",
        radio4: "",
        radio6: "",
        radio7: "",
        radio8: "",
        radio9: "",
        radio10: "",
        radio11: "",
        radio12: "",
        radio13: "",
        radio14: "",
        radio15: "",
        radio16: "",
      },
    };
  },
  methods: {
    go() {
      this.$router.push("/instrument");
    },
    btn() {
      for (const key in this.form) {
        if (this.form[key] == "") {
          if (this.form[key] != "radio2" || this.form[key] != "radio3") {
            this.$toast.fail("请填完整表单");
            return;
          }
        }
      }
      let result = [];
      let grade = 0;
      //年龄计算
      if (Number(this.form.radio1) < 56) {
        let arr = 0;
        arr = 56 - Number(this.form.radio1);
        arr = arr * 2;
        if (arr > 30) {
          grade += 30;
          result.push({
            name: "年龄",
            grade: 30,
          });
        } else {
          grade += arr;
          result.push({
            name: "年龄",
            grade: arr,
          });
        }
      } else {
        grade += 5;
        result.push({
          name: "年龄",
          grade: 5,
        });
      }
      console.log(grade, "n1");
      //第二题和第三题分数计算
      if (this.form.radio2 != "" || this.form.radio3 != "") {
        if (Number(this.form.radio2) > Number(this.form.radio3)) {
          grade += Number(this.form.radio2);
          result.push({
            name: "教育背景",
            grade: Number(this.form.radio2),
          });
        } else {
          grade += Number(this.form.radio3);
          result.push({
            name: "专业技术职称和技能等级",
            grade: Number(this.form.radio3),
          });
        }
      } else {
        grade += 0;
      }
      console.log(grade, "n2或n3");

      // 第四题分数计算
      if (this.form.radio4 != "") {
        if (Number(this.form.radio4) == 0) {
          grade += 0;
          result.push({
            name: "在本市工作及缴纳职工社会保险年限",
            grade: 0,
          });
        } else {
          if (this.radio4_con) {
            grade += Number(this.radio4_con) * 3;
            result.push({
              name: "在本市工作及缴纳职工社会保险年限",
              grade: Number(this.radio4_con) * 3,
            });
          } else {
            grade += 0;
            result.push({
              name: "在本市工作及缴纳职工社会保险年限",
              grade: 0,
            });
          }
        }
      } else {
        grade += 0;
      }
      console.log(grade, "n4");
      // 第六题分数计算
      if (this.form.radio6 != "") {
        if (Number(this.form.radio6) == 0) {
          grade += 0;
          result.push({
            name: "投资纳税或带动本地就业",
            grade: 0,
          });
        } else {
          if (Number(this.radio6_con) > 10) {
            grade += 10;
            result.push({
              name: "投资纳税或带动本地就业",
              grade: 10,
            });
          } else {
            grade += 0;
            result.push({
              name: "投资纳税或带动本地就业",
              grade: 0,
            });
          }
        }
      } else {
        grade += 0;
      }
      console.log(grade, "n6");
      // 第七题分数计算
      if (this.form.radio7 != "") {
        grade += Number(this.form.radio7);
        result.push({
          name: "纳税保险社会基数",
          grade: Number(this.form.radio7),
        });
      } else {
        grade += 0;
      }
      console.log(grade, "n7");
      // 第八题分数计算
      if (this.form.radio8 != "") {
        if (Number(this.form.radio8) == 0) {
          grade += 0;
          result.push({
            name: "特定的公共服务领域",
            grade: 0,
          });
        } else {
          if (Number(this.radio8_con) > 5) {
            let num = 0;
            num = Number(this.radio8_con) - 5;
            num = num * 4;
            grade += num;
            result.push({
              name: "特定的公共服务领域",
              grade: num,
            });
          } else {
            grade += 0;
            result.push({
              name: "特定的公共服务领域",
              grade: 0,
            });
          }
        }
      } else {
        grade += 0;
      }
      console.log(grade, "n8");
      // 第九题分数计算
      if (this.form.radio9 != "") {
        if (Number(this.form.radio9) == 0) {
          grade += 0;
          result.push({
            name: "远郊重点区城",
            grade: 0,
          });
        } else {
          if (Number(this.radio9_con) > 5) {
            let num = 0;
            num = Number(this.radio9_con) - 5;
            num = num * 2;
            grade += num;
            result.push({
              name: "远郊重点区城",
              grade: num,
            });
          } else {
            grade += 0;
            result.push({
              name: "远郊重点区城",
              grade: 0,
            });
          }
        }
      } else {
        grade += 0;
      }
      console.log(grade, "n9");
      // 第十题分数计算
      if (this.form.radio10 != "") {
        grade += Number(this.form.radio10);
        result.push({
          name: "是否全日制应届高校大学毕业生",
          grade: Number(this.form.radio10),
        });
      } else {
        grade += 0;
      }
      console.log(grade, "n10");
      // 第十一题分数计算
      if (this.form.radio11 != "") {
        grade += Number(this.form.radio11);
        result.push({
          name: "您在上海工作期间是否获得表彰奖励",
          grade: Number(this.form.radio11),
        });
      } else {
        grade += 0;
      }
      console.log(grade, "n11");
      // 第十二题分数计算
      if (this.form.radio12 != "") {
        if (Number(this.form.radio12) == 0) {
          grade += 0;
          result.push({
            name: "配偶为本市户籍人员",
            grade: 0,
          });
        } else {
          let num = 0;
          num = Number(this.radio12_con) * 4;
          grade += num;
          result.push({
            name: "配偶为本市户籍人员",
            grade: num,
          });
        }
      } else {
        grade += 0;
      }
      console.log(grade, "n12");
      // 第十三题分数计算
      if (this.form.radio13 != "") {
        grade += Number(this.form.radio13);
        result.push({
          name: "三年内申请积分提供虚假材料",
          grade: Number(this.form.radio13),
        });
      } else {
        grade += 0;
      }
      console.log(grade, "n13");
      // 第十四题分数计算
      if (this.form.radio14 != "") {
        grade += Number(this.form.radio14);
        result.push({
          name: "五年内行政拘留记录",
          grade: Number(this.form.radio14),
        });
      } else {
        grade += 0;
      }
      console.log(grade, "n14");
      // 第十五题分数计算
      if (this.form.radio15 != "") {
        result.push({
          name: "五年内一般刑事犯罪记录",
          grade: Number(this.form.radio15),
        });
        grade += Number(this.form.radio15);
      } else {
        grade += 0;
      }
      console.log(grade, "n15");
      // 第十六题分数计算
      if (this.form.radio16 != "") {
        if (Number(this.form.radio16) == 0) {
          grade += 0;
          result.push({
            name: "违反国家及本市计划生育政策",
            grade: 0,
          });
        } else {
          result.push({
            name: "违反国家及本市计划生育政策",
            grade: 0,
          });
          grade = 0;
        }
      } else {
        grade += 0;
      }
      if (grade < 0) {
        grade = 0;
      }
      console.log(grade, "n16");
      this.$store.commit("SET_SCORERESSULTSLIST", result);
      this.$router.push({
        path: "/scoreresults",
        query: {
          grade,
        },
      });
    },
  },
  mounted() {
  
  },
};
</script>

<style lang='scss' scoped>
.header {
  overflow: hidden;
  height: 4.5rem;
  background: url("../assets/userimg.jpg") no-repeat;
  background-size: 100%;
  .t {
    margin-top: 0.3rem;
    margin-left: 0.2rem;
    img {
      width: 0.4rem;
      margin-right: 0.5rem;
    }
    display: flex;
    align-items: center;
    font-size: 0.34rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .d {
    margin-top: 0.2rem;
    margin-left: 0.7rem;
    font-size: 0.34rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
.content {
  margin-top: -1.5rem;
  overflow: hidden;
  padding: 0 0.2rem;
  box-sizing: border-box;
  position: relative;
  width: 6.7rem;
  margin-left: 0.45rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.12rem 0rem rgba(156, 156, 156, 0.23);

  border-radius: 0.1rem;
  .title {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 0.56rem;
    width: 3.34rem;
    height: 0.56rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;

    background: #4875cb;
    border-radius: 0px 0px 0.5rem 0.5rem;
  }
  .item1 {
    .js {
      margin-left: 0.4rem;
      margin-bottom: 0.2rem;
      font-size: 0.22rem;
      color: #cdcdcd;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .subject {
      margin: 0.2rem 0;
      font-size: 0.26rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #7c7c7c;
    }
    .content1 {
      margin-left: 0.6rem;
      margin-bottom: 0.2rem;
      font-size: 0.26rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #7c7c7c;
    }
  }
}
.btn {
  margin-bottom: 0.2rem;
}
.btn1 {
  margin-left: 0.32rem;
  margin-bottom: 0.3rem;
  text-align: center;
  line-height: 0.7rem;
  width: 6.86rem;
  height: 0.7rem;
  background: linear-gradient(90deg, #458aec 0%, #6ab4fd 100%);
  border-radius: 0.48rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>